<template>
    <div>
        <v-layout wrap justify-center class="hidden-xs-only" @click="openVideo">
            <v-flex xs12 py-3 :style="{
                backgroundColor: '#005f32',
                height: $vuetify.breakpoint.name === 'xs'
                    ? '1200px'
                    : $vuetify.breakpoint.name === 'sm'
                        ? '550px'
                        : $vuetify.breakpoint.name === 'md'
                            ? '500px'
                            : $vuetify.breakpoint.name === 'lg'
                                ? '400px'
                                : '650px' // xl
            }" style="background-color:#005f32">
                <v-layout wrap justify-center py-10>


                    <v-flex xs12 py-3>
                        <span style=" color: white;font-family: montserratbold;font-size: 20px; ">
                            The CBRC Story
                        </span>
                    </v-flex>
                    <v-flex xs9 text-center>
                        <span style=" color: white;font-family: poppinsregular;font-size: 14px; ">
                            The Centre for Bear Rehabilitation and Conservation (CBRC) in Pakke Tiger Reserve, Arunachal
                            Pradesh is India's only facility dedicated to hand-raising and rehabilitating orphaned
                            Asiatic black bear cubs.
                        </span>
                    </v-flex>

                    <v-flex xs6 text-center py-3>
                        <span style=" color: white;font-family: poppinsregular;font-size: 14px; ">
                            Since its establishment in 2002 by the Arunachal Pradesh Forest Department, Wildlife
                            Trust of India (WTI), and the International Fund for Animal Welfare (IFAW), CBRC has
                            successfully rehabilitated and released over 70 bear cubs back into their natural habitat.
                        </span>
                    </v-flex>
                </v-layout>

            </v-flex>




        </v-layout>
        <v-layout wrap justify-center class="hidden-xs-only" style="position: relative;">
            <v-flex xs12>
                <v-card :style="{

                    top: $vuetify.breakpoint.name === 'xs'
                        ? '0px'
                        : $vuetify.breakpoint.name === 'sm'
                            ? '-190px'
                            : $vuetify.breakpoint.name === 'md'
                                ? '-180px'
                                : $vuetify.breakpoint.name === 'lg'
                                    ? '-120px'
                                    : '-120px' // xl
                }" outlined style="position: absolute;  left: 50%; transform: translateX(-50%); z-index: 2;">
                    <iframe width="650px" height="450px" src="https://www.youtube.com/embed/yH4qYuvG5Jg"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen>
                    </iframe>
                   </v-card>
            </v-flex>
        </v-layout>
       
        <v-layout wrap justify-center class="hidden-xs-only">
            
            <v-flex xs12 :style="{
                backgroundColor: 'white',
                height: $vuetify.breakpoint.name === 'xs'
                    ? '100%'
                    : $vuetify.breakpoint.name === 'sm'
                        ? '350px'
                        : $vuetify.breakpoint.name === 'md'
                            ? '350px'
                            : $vuetify.breakpoint.name === 'lg'
                                ? '380px'
                                : '600px' 
            }" style="background-color: white;">
            </v-flex>
          
            <v-flex py-6 xs12 style="color: black; text-align: center; margin-top: -40px;font-family:poppinsbold;font-size:20px" @click="scrollToTop">
                Gift Them a Second Chance At Life
            </v-flex>

        </v-layout>

        <v-layout wrap justify-center class="hidden-sm-and-up" @click="openVideo">
            <v-flex xs12 py-3  style="background-color:#005f32">
                <v-layout wrap justify-center py-10>


                    <v-flex xs12 py-3>
                        <span style=" color: white;font-family: montserratbold;font-size: 20px; ">
                            The CBRC Story
                        </span>
                    </v-flex>
                    <v-flex xs9 text-center>
                        <span style=" color: white;font-family: poppinsregular;font-size: 14px; ">
                            The Centre for Bear Rehabilitation and Conservation (CBRC) in Pakke Tiger Reserve, Arunachal
                            Pradesh is India's only facility dedicated to hand-raising and rehabilitating orphaned
                            Asiatic black bear cubs.
                        </span>
                    </v-flex>

                    <v-flex xs6 text-center py-3>
                        <span style=" color: white;font-family: poppinsregular;font-size: 14px; ">
                            Since its establishment in 2002 by the Arunachal Pradesh Forest Department, Wildlife
                            Trust of India (WTI), and the International Fund for Animal Welfare (IFAW), CBRC has
                            successfully rehabilitated and released over 70 bear cubs back into their natural habitat.
                        </span>
                    </v-flex>
                </v-layout>

            </v-flex>




        </v-layout>

        <v-layout py-3 wrap justify-center class="hidden-sm-and-up">
            <v-flex xs12>
              <div class="video-wrapper">
                <iframe 
                  src="https://www.youtube.com/embed/yH4qYuvG5Jg"
                  title="YouTube video player" 
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen>
                </iframe>
              </div>
            </v-flex>
          </v-layout>
          <v-flex py-2 pt-12  class="hidden-sm-and-up" xs12 style="color: black; text-align: center; margin-top: -40px;font-family:poppinsbold;font-size:20px" @click="scrollToTop">
            Gift Them a Second Chance At Life
        </v-flex>
          


    </div>
</template>
<script>
export default {
    methods: {
        scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
        openVideo() {
            window.open(" https://www.youtube.com/watch?v=yH4qYuvG5Jg", "_blank");
        }
    }
};
</script>


<style>
.video-wrapper {
  position: relative;
  width: 100%; /* Make the video take the full width */
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0; /* Set height to zero */
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Make iframe fill the container */
  height: 100%;
}
</style>
